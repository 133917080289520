import { Icon } from "ol/style";

import { DEFAULT_PIC_SCALE } from "./constants";
import getStyleZIndex from "./getStyleZIndex";
import { isPicture } from "./styleUtils";

export const isFeatureOutsideZoomLimit = (feature, map) => {
  const zoom = map.getView().getZoom();
  const minZoom = feature.get("minZoom");
  const maxZoom = feature.get("maxZoom");
  return minZoom > zoom || zoom > maxZoom;
};

/**
 * Creates style array for style function considering zoom limits, pictureOptions, selectStyle
 * @param {*} feature Feature from the style function arguments
 * @param {*} resolution Resolution from style function arguments
 * @param {*} styles Style to be applied
 * @param {*} map Openlayers map
 * @param {*} ignoreZoomLimits Ignore the style defined by zoom limits
 * @param {*} selectStyles Array containing the select styles
 * @param {*} size Icon dimensions, used for dynamic scaling of picture select style frame
 */
const createStyles = (
  feature,
  resolution,
  styles,
  map,
  ignoreZoomLimits,
  selectStyles = [],
) => {
  const isOutsideZoomLimit =
    !ignoreZoomLimits && isFeatureOutsideZoomLimit(feature, map);
  const isSelected = selectStyles?.length;

  // When the feature is outside the zoom limit and not selected, we don't render it.
  if (!isSelected && isOutsideZoomLimit) {
    styles.forEach((style) => {
      style?.setRenderer(() => null);
    });
    return styles;
  }

  // When the feature is inside the zoom limit, we use the default renderer for all styles.
  styles.forEach((style) => {
    style?.setRenderer();
  });

  const mainStyle = styles[0];
  const otherStyles = styles.slice(1, styles.length); // lineStart and lineEnd styles

  // Adapt the scale of the picture to the resolution
  if (styles[0].getImage() instanceof Icon && isPicture(mainStyle)) {
    const pictureOptions = feature.get("pictureOptions");
    const iconScale =
      pictureOptions && resolution
        ? (pictureOptions.resolution / resolution) * DEFAULT_PIC_SCALE
        : DEFAULT_PIC_SCALE;
    mainStyle.getImage().setScale(iconScale);
  }

  // We apply a zIndex to style depending on the geometry or type of feature
  [mainStyle, ...otherStyles].forEach((style) => {
    style.setZIndex(getStyleZIndex(feature, isPicture(styles[0])));
  });

  return [mainStyle, ...otherStyles];
};

export default createStyles;
