/* eslint-disable no-nested-ternary */
import { Icon } from "ol/style";

import environment from "../envConfig";

export const getFeatureStyle = (feature, resolution) => {
  return (
    feature &&
    feature.getStyleFunction() &&
    (feature.getStyleFunction()(feature, resolution)[0] ||
      feature.getStyleFunction()(feature, resolution))
  );
};

export const isIcon = (style) => {
  let style0 = style;
  if (Array.isArray(style)) {
    [style0] = style;
  }
  return style0 && style0.getImage() instanceof Icon;
};

export const isPicture = (style) => {
  let style0 = style;
  if (Array.isArray(style)) {
    [style0] = style;
  }
  return (
    isIcon(style0) &&
    new RegExp(`${environment.app.imagesUrl}pictures/`).test(
      style0.getImage().getSrc(),
    )
  );
};

export const isIconsGroup = (style) => {
  let style0 = style;
  if (Array.isArray(style)) {
    [style0] = style;
  }

  if (!isIcon(style0)) {
    return false;
  }

  // Returns true if icon url contains the pictos url and commas, indicating it's a group icon
  const regex = /(icon-generator)(?=.*.geops.io\/pictogram)/;
  const src = style0.getImage().getSrc();
  try {
    return (
      regex.test(src) &&
      !!(new URL(src).searchParams.get("fill")?.split(",").length > 1)
    );
  } catch (err) {
    // there is no valid url to test.
    return false;
  }
};

export const isCustomIcon = (feature, resolution) => {
  let iconSource;
  // Returns true if the selected feature is a custom icon
  if (
    getFeatureStyle(feature, resolution) &&
    getFeatureStyle(feature, resolution).getImage() instanceof Icon
  ) {
    iconSource = getFeatureStyle(feature, resolution)
      .getImage()
      .getSrc()
      .includes(environment.app.pictosUrl);
  }
  return iconSource;
};

export const getRadiansFromDegrees = (degrees) => {
  return (degrees * Math.PI) / 180;
};

export const getDegreesFromRadians = (radians) => {
  return radians * (180 / Math.PI);
};

export const getValidOpacity = (fillOrStroke) => {
  if (!fillOrStroke) return undefined;
  const opacity = fillOrStroke.getColor()?.[3];
  if (opacity === 0) return 0;
  return opacity || 1;
};

const cache = {};

export const createHighlightedIcon = (
  scale,
  size,
  strokeStyle,
  strokeWidth = 4,
  offset = 4,
  cacheSuffix = "",
  fillStyle,
) => {
  const key = [
    scale,
    size,
    strokeStyle,
    strokeWidth,
    offset,
    cacheSuffix,
    fillStyle,
  ].join();

  if (cache[key]) {
    // Cache essential for performance
    return cache[key];
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const padding = strokeWidth + offset;

  // Set new canvas dimensions adjusted for border
  canvas.width = Math.ceil(scale * size[0] + padding) || 2;
  canvas.height = Math.ceil(scale * size[1] + padding) || 2;

  if (strokeStyle && strokeWidth > 0) {
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = strokeWidth;
    ctx.strokeRect(0, 0, canvas.width, canvas.height);
  }

  if (fillStyle) {
    ctx.fillStyle = fillStyle;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }

  cache[key] = canvas; // Cache essential for performance

  return cache[key];
};

export const createHighlightedIconStyle = (
  rotation,
  scale,
  size,
  strokeStyle,
  strokeWidth,
  fillStyle,
  offset,
  rotateWithView = false,
) => {
  const key = [
    "highlightedIconStyle",
    rotation,
    scale,
    size,
    strokeStyle,
    strokeWidth,
    fillStyle,
    offset,
  ].join();

  if (cache[key]) {
    // Cache essential for performance
    return cache[key];
  }

  const canvas = createHighlightedIcon(
    scale,
    size,
    strokeStyle,
    strokeWidth,
    offset,
    "",
    fillStyle,
  );

  cache[key] = new Icon({
    crossOrigin: "Anonymous",
    img: canvas,
    rotateWithView,
    rotation,
    size: [canvas.width, canvas.height],
  });

  return cache[key];
};

export const brewTransparentColor = (colorArray, opacity = 1) => {
  if (!Array.isArray(colorArray)) return null;
  const newColor = [...colorArray.slice(0, 3), opacity];
  return newColor;
};
